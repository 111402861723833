import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  overflow-x: hidden;
  .svg-inline--fa {
    display: initial;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  // transition: 0.375s;
  @media only screen and (max-width: 62rem) {
    width: calc(100vw + 40rem);
    margin-left: ${props => (props.expand ? '0' : 'calc(-32rem)')};
  }
  @media only screen and (max-width: 38rem) {
    width: calc(200vw - 6rem);
    margin-left: ${props => (props.expand ? '0' : 'calc(-100vw + 6rem)')};
  }
`

export const Block = styled.div`
  transition: 0.25s;
  div div {
    ${props => props.blur && `filter: blur(0.25rem);`};
  }
`

export const ToggleBack = styled.div`
  padding: 0.75rem 1.5rem;
  transform: translateY(-50%) rotate(90deg) translateY(100%);
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border: ${props => props.theme.colors.black};
  display: none;
  position: fixed;
  top: 50%;
  z-index: 10;
  @media only screen and (max-width: 62rem) {
    display: block;
    left: 35rem;
  }
  @media only screen and (max-width: 48rem) {
    left: auto;
    right: 0;
    transform: translateY(-50%) rotate(90deg) translateY(-100%);
  }
`

export const IconWrapper = styled.div`
  margin-right: 1em;
  align-self: center;
  display: inline-block;
  .svg-inline--fa {
    display: initial;
  }
`

export const BackContainer = styled.div`
  display: flex;
  font-family: ${props => props.theme.fonts.mono};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 3.5rem;
  padding: 1.5rem 2rem;
  font-size: 1.25rem;
  border-bottom: 1px solid ${props => props.theme.colors.black};

  a {
    color: inherit;
    text-decoration: none;
  }

  @media only screen and (max-width: 48rem) {
    font-size: 0.875rem;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  padding: 2rem;

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
`

export const Name = styled.h1`
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 100;
`
export const Title = styled.p`
  margin-top: 0;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.mono};
`
export const Md = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 1.25rem;
  max-width: 20em;
  a {
    color: inherit;
  }
`

export const Left = styled.div`
  width: 50%;
  margin-right: 2rem;
  @media only screen and (max-width: 48rem) {
    margin-right: 0;
    margin-bottom: 4rem;
    width: 100%;
  }
`
export const Right = styled.div`
  width: 50%;
  margin-left: 2rem;
  @media only screen and (max-width: 48rem) {
    margin-left: 0;
    width: 100%;
  }
`

export const CreditContainer = styled.div`
  margin: 2rem;
  border-bottom: 1px solid ${props => props.theme.colors.black};
`

export const LinkContainer = styled.a`
  color: inherit;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 0.5rem;

  span {
    text-decoration: underline;
  }

  ${IconWrapper} {
    margin-right: 0.5em;
    display: inline-block;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
`
