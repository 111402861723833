import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowLeft, faGlobe } from '@fortawesome/pro-light-svg-icons'
import { faInstagram, faTwitter } from '@fortawesome/fontawesome-free-brands'

import ChangeTitle from 'containers/change-title'
import {
  Container,
  Content,
  Block,
  ToggleBack,
  IconWrapper,
  BackContainer,
  InfoContainer,
  Name,
  Title,
  Md,
  Left,
  Right,
  CreditContainer,
  LinkContainer,
} from './styles'

class CollaboratorTemplate extends React.PureComponent {
  render() {
    const {
      name,
      title,
      group,
      field,
      bio,
      image,
      linkWebsite,
      displayWebsite,
      linkInstagram,
      displayInstagram,
      linkTwitter,
      displayTwitter,
      matchedStories,
      matchedContext,
    } = this.props.data.contentfulCollaborator

    const creditEdges = this.props.data.matchedStories.edges.concat(
      this.props.data.matchedContext
    )

    return (
      <Container>
        <ChangeTitle title={name} />
        <div className="wrapper">
          <BackContainer>
            <Link to="/collaborators">
              <IconWrapper>
                <FontAwesomeIcon icon={faArrowLeft} size="1x" />
              </IconWrapper>
              All Contributers
            </Link>
          </BackContainer>
          <InfoContainer>
            <Left>
              {name && <Name>{name}</Name>}
              {field ? <Title>{field}</Title> : title && <Title>{title}</Title>}
              {bio && (
                <Md
                  dangerouslySetInnerHTML={{
                    __html: bio.childMarkdownRemark.html,
                  }}
                />
              )}
              {linkWebsite && (
                <>
                  <LinkContainer href={linkWebsite} target="_blank">
                    <IconWrapper>
                      <FontAwesomeIcon icon={faGlobe} size="1x" />
                    </IconWrapper>
                    <span>{displayWebsite || linkWebsite}</span>
                  </LinkContainer>
                  <br />
                </>
              )}
              {linkTwitter && (
                <>
                  <LinkContainer href={linkTwitter} target="_blank">
                    <IconWrapper>
                      <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </IconWrapper>
                    <span>{displayTwitter || linkTwitter}</span>
                  </LinkContainer>
                  <br />
                </>
              )}
              {linkInstagram && (
                <>
                  <LinkContainer href={linkInstagram} target="_blank">
                    <IconWrapper>
                      <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </IconWrapper>
                    <span>{displayInstagram || linkInstagram}</span>
                  </LinkContainer>
                  <br />
                </>
              )}
            </Left>
            <Right>
              {image && <Img fluid={image.fluid} style={{ width: '100%' }} />}
            </Right>
          </InfoContainer>
          {/* Currently no Credits
              <CreditContainer>Credits</CreditContainer>*/}
        </div>
      </Container>
    )
  }
}

export default CollaboratorTemplate

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CollaboratorBySlug($id: String!) {
    contentfulCollaborator(id: { eq: $id }) {
      name
      title
      group
      field
      image {
        id
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bio {
        id
        childMarkdownRemark {
          id
          html
        }
      }
      linkWebsite
      displayWebsite
      linkInstagram
      displayInstagram
      linkTwitter
      displayTwitter
    }
    matchedStories: allContentfulStory(
      filter: { credits: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          credits {
            id
          }
        }
      }
    }
    matchedContext: allContentfulContext(
      filter: { author: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          author {
            id
          }
        }
      }
    }
  }
`
